import { FaQuestionCircle, FaHandshake, FaPlane, FaHome } from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";
import { RiArticleFill } from "react-icons/ri";
import { MdAirportShuttle } from "react-icons/md";

export const links = [
{
  label: 'Classic & Exotic Cars',
  href: '/jjbest/classic-car-financing/no-nav',
  // icon: <FaCar />,
},
{
  label: 'Boats of Any Age',
  href: '/jjbest/boat-financing/no-nav',
  // icon: <FaCar />,
},
{
  label: 'Aircraft, RVs & Coins',
  href: '/jjbest/airplane-financing',
  // icon: <FaCar />,
  children: [
    {
      label: 'Aircraft Financing',
      subLabel: 'Trending Design to inspire you',
      href: '/jjbest/airplane-financing/no-nav',
      icon: <FaPlane />
    },
    {
      label: 'RV Loans',
      subLabel: 'Trending Design to inspire you',
      href: '/jjbest/rv-financing/no-nav',
      icon: <MdAirportShuttle />
    },
    {
      label: 'Home Link',
      subLabel: 'Trending Design to inspire you',
      href: '/jjbest/classic-car-financing',
      icon: <FaHome />
    },
]
},
{
  label: 'About Us',
  href: '/jjbest/classic-car-financing',
  // icon: <FaCar />,
  children: [
    {
      label: 'About JJ Best',
      subLabel: 'Trending Design to inspire you',
      href: '/jjbest/about-jjbest',
      icon: <BsPeopleFill />
    },
    {
      label: 'Dealer Connect',
      subLabel: 'Trending Design to inspire you',
      href: '/jjbest/classic-car-financing',
      icon: <FaHandshake />
    },
    {
      label: 'FAQ',
      href: '/jjbest/about-jjbest/faq',
      subLabel: 'Trending Design to inspire you',
      icon: <FaQuestionCircle />
    },
]
},
{
  label: 'Resources',
  // icon: <FaCar />,
  children: [
    {
      label: 'Financing Tips',
      subLabel: 'Trending Design to inspire you',
      href: '/jjbest/resources',
      icon: <RiArticleFill />
    },
    // {
    //   label: 'Search All Financing Tips',
    //   href: '/jjbest/resources/searchbytag/all',
    //   subLabel: 'Trending Design to inspire you',
    //   icon: <AiOutlineFileSearch />
    // },
    {
      label: 'Buyers Guides',
      href: '/jjbest/specialty-financing/buyers-guides',
      subLabel: 'Trending Design to inspire you',
      icon: <AiOutlineFileSearch />
    },
    {
      label: "Search Financing Tips and Buyer's Guides",
      href: '/jjbest/specialty-financing/buyers-guides/searchbytag/all',
      subLabel: 'Trending Design to inspire you',
      icon: <AiOutlineFileSearch />
    },
    
]
},
]
