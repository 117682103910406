/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        items {
          id
          applicationId
          interestRate
          loanAmount
          loanTerm
          monthlyPayment
          optionName
          totalInterest
          totalPayments
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicationGroupName
        applicationSource
        applicationName
        collateralType
        vehicleYear
        make
        model
        purchasePrice
        loanAmount
        firstName
        lastName
        applicationType
        cellPhone
        email
        ssn
        dob
        address
        city
        applicantState
        zipCode
        yearsAtResidence
        residenceType
        employmentStatus
        employer
        employmentPosition
        yearsInPosition
        yearlyIncome
        linkToImages
        disclaimerCheck
        insuranceInterest
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationVersion = /* GraphQL */ `
  query GetApplicationVersion($id: ID!) {
    getApplicationVersion(id: $id) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplicationVersions = /* GraphQL */ `
  query ListApplicationVersions(
    $filter: ModelApplicationVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        interestRate
        loanAmount
        loanTerm
        monthlyPayment
        optionName
        totalInterest
        totalPayments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      clientId
      tagName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        tagName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appsByAppId = /* GraphQL */ `
  query AppsByAppId(
    $applicationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByAppId(
      applicationId: $applicationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        interestRate
        loanAmount
        loanTerm
        monthlyPayment
        optionName
        totalInterest
        totalPayments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      title
      author
      synopsis
      metaDescription
      category
      url
      isFeatured
      articleUpdateTimeStamp
      articleImages {
        items {
          id
          articleID
          articleImageFile
          createdAt
          updatedAt
        }
        nextToken
      }
      articleTags {
        items {
          id
          articleID
          articleTagName
          createdAt
          updatedAt
        }
        nextToken
      }
      articleSections {
        items {
          id
          articleID
          sectionHeader
          sectionContent
          sectionContentMarkup
          sectionOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $id: ID
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listArticles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        author
        synopsis
        category
        url
        isFeatured
        articleUpdateTimeStamp
        articleImages {
          items {
            id
            articleID
            articleImageFile
            articleImageObject {
              bucket
              region
              key
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        articleTags {
          items {
            id
            articleID
            articleTagName
            createdAt
            updatedAt
          }
          nextToken
        }
        articleSections {
          items {
            id
            articleID
            sectionHeader
            sectionContent
            sectionContentMarkup
            sectionOrder
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articleByCategory = /* GraphQL */ `
  query ArticleByCategory(
    $category: String!
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleByCategory(
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        author
        metaDescription
        synopsis
        category
        url
        isFeatured
        articleUpdateTimeStamp
        articleImages {
          items {
            id
            articleID
            articleImageFile
            articleImageObject {
              bucket
              region
              key
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        articleTags {
          items {
            id
            articleID
            articleTagName
            createdAt
            updatedAt
          }
          nextToken
        }
        articleSections {
          items {
            id
            articleID
            sectionHeader
            sectionContent
            sectionContentMarkup
            sectionOrder
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articleByUrl = /* GraphQL */ `
  query ArticleByUrl(
    $url: String!
    $isFeatured: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleByUrl(
      url: $url
      isFeatured: $isFeatured
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        author
        metaDescription
        synopsis
        category
        url
        isFeatured
        articleUpdateTimeStamp
        articleImages {
          items {
            id
            articleID
            articleImageFile
            articleImageObject {
              bucket
              region
              key
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        articleTags {
          items {
            id
            articleID
            articleTagName
            createdAt
            updatedAt
          }
          nextToken
        }
        articleSections {
          items {
            id
            articleID
            sectionHeader
            sectionContent
            sectionContentMarkup
            sectionOrder
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticleTag = /* GraphQL */ `
  query GetArticleTag($id: ID!) {
    getArticleTag(id: $id) {
      id
      articleID
      articleTagName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticleTags = /* GraphQL */ `
  query ListArticleTags(
    $filter: ModelArticleTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        articleTagName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articleTagsByArticleIDAndArticleTagName = /* GraphQL */ `
  query ArticleTagsByArticleIDAndArticleTagName(
    $articleID: ID!
    $articleTagName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleTagsByArticleIDAndArticleTagName(
      articleID: $articleID
      articleTagName: $articleTagName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleID
        articleTagName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticleSection = /* GraphQL */ `
  query GetArticleSection($id: ID!) {
    getArticleSection(id: $id) {
      id
      articleID
      sectionHeader
      sectionContent
      sectionContentMarkup
      sectionOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticleSections = /* GraphQL */ `
  query ListArticleSections(
    $filter: ModelArticleSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        sectionHeader
        sectionContent
        sectionContentMarkup
        sectionOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articleSectionsByArticleIDAndSectionOrder = /* GraphQL */ `
  query ArticleSectionsByArticleIDAndSectionOrder(
    $articleID: ID!
    $sectionOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleSectionsByArticleIDAndSectionOrder(
      articleID: $articleID
      sectionOrder: $sectionOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleID
        sectionHeader
        sectionContent
        sectionContentMarkup
        sectionOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticleImage = /* GraphQL */ `
  query GetArticleImage($id: ID!) {
    getArticleImage(id: $id) {
      id
      articleID
      articleImageFile
      articleImageObject {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticleImages = /* GraphQL */ `
  query ListArticleImages(
    $filter: ModelArticleImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleID
        articleImageFile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const articleImagesByArticleIDAndArticleImageFile = /* GraphQL */ `
  query ArticleImagesByArticleIDAndArticleImageFile(
    $articleID: ID!
    $articleImageFile: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleImagesByArticleIDAndArticleImageFile(
      articleID: $articleID
      articleImageFile: $articleImageFile
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleID
        articleImageFile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
