import React, { useEffect, useState } from 'react';
import {
  Container,
  SimpleGrid,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  List,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import FAQHero from '../../../../components/jjbest/FAQHero';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';
import JJBestDisclosures from '../../../../components/jjbest/JJBestDisclosures';
import JJBestFeatures from '../../../../components/jjbest/JJBestFeatures';
import ClassicCarExamples from '../../../../components/jjbest/ClassicCarExamples';
import FaqSectionHeader from '../../../../components/jjbest/FaqSectionHeader';
import protectedRoute from "../../../../utils/protectedRoute";
import { faqs } from '../../../../data/_jjbest_faqs';

const Faq = (props) => {
  const { userInfo } = props;
  const [selectedFAQs, setSelectedFAQs] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleFilteredCategories = () => {
      setSelectedFAQs(faqs)
      setLoading(false);
    };
    handleFilteredCategories();
  }, [userInfo]);

  const categories = [
    'All questions',
    'Before application',
    'After application',
    'Payments',
    'Credit related',
    'About us',
    'Car titles',
    'Insurance'
  ];

  return (loading ? <div></div> :
    <LandingLayoutJJBest>
      <FAQHero />
      <Container py={{ base: '16', md: '24' }} maxW={'8xl'} alignItems="center">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '12', lg: '24' }}>
          <FaqSectionHeader
            headline="All questions"
            description="Learn more about the J.J. Best loan program."
            tagline="Frequently asked questions"
            alignItems="start"
            maxW="lg"
          >
            <List spacing={3}>
              {categories.map((item, id) => (
                <Link key={id} href={`/jjbest/about-jjbest/faq/${item}`}>
                <ListItem >
                  <Text fontWeight="medium" fontSize="xl">{item}</Text>
                </ListItem>
                </Link>
              ))}
            </List>
          </FaqSectionHeader>

          <Stack gap={{ base: '6', md: '12' }} alignItems="center">
            <Box
              width="100%"
              mx="auto"
              border="1px solid #E2E8F0"
              alignItems="center"
            >
              <Accordion
                allowToggle
                multiple
                defaultValue={[selectedFAQs[0].question]}
                maxW="8xl"
              >
                {selectedFAQs && selectedFAQs.map((item) => (
                  <AccordionItem key={item.id} value={item}>
                    <h2>
                      <AccordionButton
                        textStyle="lg"
                        py="4"
                        fontWeight="medium"
                        fontSize="xl"
                        bgColor="gray.50"
                      >
                        <Box as="span" flex="1" textAlign="left">
                          {item.question}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {item.answer_items && item.answer_items.length === 1 && (
                      <AccordionPanel pb={4} color="fg.muted">
                      {/* {answer} */}
                      {item.answer_items[0]}
                      </AccordionPanel>
                    )}
                    {item.answer_items && item.answer_items.length > 1 && (
                      <AccordionPanel pb={4} color="fg.muted">
                        <Text>{item.answer_items[0]}</Text>
                        <br></br>
                        <UnorderedList spacing={3}>
                          {item.answer_items.slice(1).map((element, id) => (
                      
                            <ListItem key={id}>
                              {element}
                            </ListItem>
              
                          ))}
                        </UnorderedList>
                      </AccordionPanel>
                    )}
                   
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <JJBestFeatures />
      <ClassicCarExamples />
      <JJBestFooter />
      <JJBestDisclosures />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(Faq);
