export const faqs = [
  {
    id: 1,
    question: 'How Do I Apply for a Loan?',
    category: 'Before application',
    answer_items: [
      'Fill out a secure, online application at www.jjbest.com/apply or call us at 1-800-USA-1965',
    ],
  },
  {
    id: 2,
    question: 'How long does it take to get approved?',
    category: 'Before application',
    answer_items: [
      'We can usually let you know in 2 to 5 minutes whether you qualify.',
    ],
  },
  {
    id: 3,
    question: 'How long does the process take?',
    category: 'Before application',
    answer_items: [
      'Your paperwork will be processed within 24 hours from the time you complete and sign all necessary original documents.',
    ],
  },
  {
    id: 4,
    question: 'How do I determine whether or not I am eligible?',
    category: 'Before application',
    answer_items: [
      'You can find out if you prequalify in as little as 2 minutes. Our general requirements include: ',
      'Good to excellent established credit',
      'At least 10% to put down towards your classic car purchase',
      'Verifiable income',
      'A good debt-to-income ratio',
      'The vehicle you wish to purchase must be in good operating condition',
    ],
  },
  {
    id: 5,
    question: "Don't most people pay cash for these cars?",
    category: 'Before application',
    answer_items: [
      'Not necessarily, because these cars usually appreciate over time. The increase in the value of the car may far exceed interest rates, making this a much more attractive option. Many more will choose to finance their purchases if an option like this is available to them.',
    ],
  },
  {
    id: 6,
    question: 'How much money should I borrow?',
    category: 'Before application',
    answer_items: [
      'You should get approved for the maximum amount you are looking to borrow. If you have a specific car in mind, ask to be qualified for 80% or 90% of the purchase price. Otherwise tell your loan officer the general amount you are looking to spend. Remember that in most cases we can add in your state’s sales tax as part of the financing program.',
    ],
  },
  {
    id: 7,
    question: 'Can I go back and change the loan amount?',
    category: 'Before application',
    answer_items: [
      'The loan amount may be changed up until the time loan documents are completed, signed and we process your check.',
    ],
  },
  {
    id: 8,
    question: 'How much do I need for a down payment?',
    category: 'Before application',
    answer_items: [
      'On average, we require a 10% to 20% down payment. Hot Rods, kit cars, and modified vehicles may require a 30% down payment.',
    ],
  },
  {
    id: 9,
    question: 'What terms do you offer?',
    category: 'Before application',
    answer_items: [
      'We offer terms from 60 to 84 months. You can reduce the length on the term of your loan yourself by making larger loan payments each month. In other words, by making a larger payment each month you will pay off the loan sooner.',
    ],
  },
  {
    id: 10,
    question: 'What rates can I expect?',
    category: 'Before application',
    answer_items: [
      'You can expect the most competitive rates in our industry. We are able to offer such low, competitive rates because we are the largest company of our kind in the United States, specializing in Antique Cars, Classic Cars, Exotic Cars, Kit Cars, Muscle Cars, Hot Rods, and Sports Cars as well as Antique Motorcycles, R.V.’s, Boats and Aircraft loans. We understand the collateral whereas most other lenders do not.',
    ],
  },
  {
    id: 11,
    question: 'What happens after I receive credit approval?',
    category: 'After application',
    answer_items: [
      'Once your application is approved, you will receive an email prompting you to call a loan officer who will walk you through the loan terms and finalize your application. Depending on your credit history, you may be required to provide additional documents, such as proof of income.',
    ],
  },
  {
    id: 12,
    question: 'What do you need from me to process my loan?',
    category: 'After application',
    answer_items: [
      'To expedite the processing of your collectible automobile loan we need the following information from you before funding the loan:',
      'A copy of the back and front of the title from the seller on the car you are buying. You or the seller can fax or email this information to us or if you prefer we can contact the seller for you.',
      'A copy of the Purchase and Sales Agreement. You or the seller can fax or email this information to us or if you prefer we can contact the seller for you. Proof of insurance listing.JJ Best Banc as Loss Payee with the address of BEST BANC BUILDING, 60 N Water St. New Bedford, MA 02740. Call J.J. Best Banc Insurance Agency for a quote at 1-888-4BEST-IN or 1-888-423-7846.',
      'Proof of down payment, i.e. copy of cashier’s check, copy of wire, copy of paypal receipt, etc. You can fax or email this information.',
      'Proof of income, i.e. two recent pay stubs. If you are self-employed, we ask for two years of signed tax returns and signed personal financial statements. You can fax or email this information. In some cases, we can waive income verification based on your credit.',
      'Legible copy of driver’s license enlarged 2x. If there is a co-applicant the same would be required of them. You can fax or email this information. If the address on the application is different than the address on the license , please provide a utility bill in your name with the correct address listed.',
      'All ORIGINAL loan documents completed, signed and notarized. These documents must be sent to us overnight. We can supply you with our UPS account number if needed please contact your loan officer. Collateral verification report. We will order this report after you have finalized the car that you will be buying and the price has been fully negotiated. This information will be provided to us by the collateral verification specialist.',
    ],
  },
  {
    id: 13,
    question: 'How long is my approval and interest rate good for?',
    category: 'After application',
    answer_items: [
      'Once you have received an approval with an interest rate and term, it is good for 30 days.',
    ],
  },
  {
    id: 14,
    question: 'Do I need to have the car appraised?',
    category: 'After application',
    answer_items: [
      'Some cars require an appraisal such as hot rods, kit cars, or modified cars. Most cars that we finance require only a physical verification of the vehicle to verify the engine starts, that the VIN number matches the title and the car is in good, working condition. We order the collateral verification when you tell us that you are buying the car and you are moving ahead with the loan. We have 1500 independent inspectors throughout the United States and the verification is usually done within 48 hours. The loan will NOT fund until this verification is completed. Please have the seller’s information available including phone number and notify the seller that one of our agents will be contacting them to complete the verification. This verification does not guarantee the merchantability of the vehicle and is not an appraisal.',
    ],
  },
  {
    id: 15,
    question: 'How do you value these cars?',
    category: 'After application',
    answer_items: [
      'We calculate the book value of the car by taking the condition of the car into consideration and we use our years of knowledge and experience, as well as auction results, Black Book, Sports Car Market, Manheim Auctions, Gold Book, Old Cars Price Guide and our knowledgeable dealer network.',
    ],
  },
  {
    id: 16,
    question: 'How much money should I borrow?',
    category: 'After application',
    answer_items: [
      'You should get approved for the maximum amount you are looking to borrow. If you have a specific car in mind, ask to be qualified for 80% or 90% of the purchase price. Otherwise tell your loan officer the general amount you are looking to spend. Remember that in most cases we can add in your state’s sales tax as part of the financing program.',
    ],
  },
  {
    id: 17,
    question: 'Why was I declined?',
    category: 'After application',
    answer_items: [
      'There are several reasons why an application may be declined. If your application is declined, a letter will be mailed to you stating the reason for declining the application. The letter will include a phone number for the credit agency that reported your credit information. The reporting agency played no part in JJ Best Banc’s decision and is unable to specify the reason(s) we have denied credit to you. You have a right under the Fair Credit Reporting Act to know all of the information contained in your credit file at the reporting agency. Within 60 days of your decline notice, you may obtain a copy of your credit report at no charge by contacting the consumer reporting agency listed, or any other credit reporting agency that maintains files on a national basis. You have the right to dispute the accuracy or the completeness of any information in your credit report by contacting the consumer reporting agency directly.',
    ],
  },
  {
    id: 18,
    question: 'Can I refinance my existing loan or lease?',
    category: 'After application',
    answer_items: [
      'Yes, J.J. Best Banc offers an automobile refinancing program that often offers a lower rate than your present rate. Because you are probably financing less money, the payments are usually substantially lower and can even compete with some home equity loans. We also offer loan refinancing programs that many times are much more competitive than leasing programs.',
    ],
  },
  {
    id: 19,
    question: 'Will you finance the residual balance on a lease?',
    category: 'After application',
    answer_items: ['Yes.'],
  },
  {
    id: 20,
    question: 'Will I receive a coupon book for my payments?',
    category: 'Payments',
    answer_items: [
'Yes, a coupon book will be mailed to you when the loan process has been completed.',
    ]
      
  },
  {
    id: 21,
    question: 'Are there any prepayment penalties on my loan?',
    category: 'Payments',
    answer_items: [
      'No.',
    ]
  },
  {
    id: 22,
    question: 'How do I know when my new payment will be due?',
    category: 'Payments',
    answer_items: [
      'When you receive your loan documentation, your payment date will be shown on the Security Agreement. It is generally due a month after the date the loan documents are signed.',
    ],
  },
  {
    id: 23,
    question: 'Where do I send my payments?',
    category: 'Payments',
    answer_items: [
      'The address is listed in the coupon book that you will receive after the loan process has been completed.',
    ],
  },
  {
    id: 24,
    question: 'Can I change the payment due date?',
    category: 'Payments',
    answer_items: [
      'Your payment due date may be changed before the loan closes please ask your J.J. Best Banc loan representative for assistance with this.',
    ],
  },
  {
    id: 25,
    question: 'What are the fees for late or missed payments?',
    category: 'Payments',
    answer_items: [
      'This depends on the agreement and is specific to your loan you can find the information stated on your signed security agreement.',
    ],
  },
  {
    id: 26,
    question: 'How is the interest calculated on my loan?',
    category: 'Payments',
    answer_items: [
      'Interest is calculated based on a simple interest calculation. Your payment is allocated to your principal balance, interest and any other fees or charges on your account. This is based on your interest rate, number of days since your last payment, the amount of your unpaid principal balance, and any other fees and charges that are applicable.',
    ],
  },
  {
    id: 27,
    question: 'Where can I find the payoff figure for my loan?',
    category: 'Payments',
    answer_items: [
      'Call us at 1-800-USA-1965 and speak with one of our representatives who will be more than happy to assist you!',
    ],
  },
  {
    id: 28,
    question: 'What if I have bad credit or a bankruptcy?',
    category: 'Credit related',
    answer_items: [
      'A bankruptcy must be discharged for a minimum of 4 years with excellent established credit since the discharge. We can make exceptions for poor credit depending on what kind of account or accounts have had issues in the past. We usually cannot approve someone if they have had late car or mortgage payments recently.',
    ],
  },
  {
    id: 29,
    question: 'Should I apply alone or with my spouse?',
    category: 'Credit related',
    answer_items: [
      'J.J. Best Bank & Co. welcomes both individual applications or joint applications from spouses. If both you and your spouse have income it will usually help the debt to income ratio and any insurance issues. Or if you both intend to be listed as owner on the title. Only the persons listed on the loan may be listed on the title.',
    ],
  },
  {
    id: 30,
    question: 'What is debt-to-income ratio?',
    category: 'Credit related',
    answer_items: [
      'Debt to income ratio is the percentage of a consumer’s monthly gross income, as reported by the credit reporting agency, that goes toward paying debts. The maximum amount that we allow is 45% of all debt including mortgage and installment debt, auto loans, student loans, etc.',
    ],
  },
  {
    id: 31,
    question: 'Who do I call with any questions that I may have?',
    category: 'About us',
    answer_items: [
      'Please call 1-800-USA-1965 or 1-508-991-8000 to reach an enthusiastic classic car loan representative at J.J. Best Banc, write to us by email at answers@jjbest.com, Fax us at 508-991-8 FAX or mail your questions to J.J. Best Banc, BEST BANC BUILDING, 60 N Water St. New Bedford, MA 02740 and a representative will assist you.',
    ],
  },
  {
    id: 32,
    question:
      'What are the hours of operation for your Customer Service Center?',
    category: 'About us',
    answer_items: [
      'Monday through Friday 9:00AM to 9:00PM, Saturday through Sunday 10:00AM to 4:00PM eastern standard time. We also accept internet questions at answers@jjbest.com 24 hours a day.',
    ],
  },
  {
    id: 33,
    question: 'Why should I use J.J. Best Banc?',
    category: 'About us',
    answer_items: [
      'J.J. Best Banc & Co. is the largest and oldest premier lender of its kind in the country. We offer fast, efficient, convenient service, affordable low rates and long terms on automobiles that most banks, credit unions and financial institutions will not touch because they do not understand the collateral as we do. We know the value and meaning of owning these wonderful machines and we enjoy them as much as you do!',
    ],
  },
  {
    id: 34,
    question: 'Is J.J. Best Banc & Co. licensed?',
    category: 'About us',
    answer_items: [
      'Yes, click here to view our Missouri License. For information on other licenses, contact the JJ Best compliance department or access J.J. Best Banc’s licensing information using NMLS.',
    ],
  },
  {
    id: 35,
    question: 'Are there fees applicable to getting financing in Missouri?',
    category: 'About us',
    answer_items: [
      'Yes. Also note that interest rates may vary on numerous factors including but not limited to loan term, credit score, debt to income ratio, collateral, loan amount, loan to value etc.',

      'J.J. Best Maximum Fees',
      'Overnight $45',
      'VSI $99',
      'Collateral Verification $125',
      'Settlement Fee $289',
      'Interest Rates 4.25%-9.99%',
    ],
  },
  {
    id: 36,
    question: 'Can you do the title work for me?',
    category: 'Car titles',
    answer_items: [
      'Yes, we are happy to complete title work for a small fee. For more information please call our title department at 1-800-455-2378.',
    ],
  },
  {
    id: 37,
    question: 'How do I register my vehicle?',
    category: 'Car titles',
    answer_items: [
      'This varies from state to state. Please contact our title department and we will be more than happy to assist you with this process.',
    ],
  },
  {
    id: 38,
    question:
      "If I don't register my car within a certain amount of time, do I pay any late fees?",
    category: 'Car titles',
    answer_items: [
      'Yes, we require you to title the car within 10 days of purchase, listing J.J. Best Banc as lien holder. If you are not planning on registering your vehicle, you are still required to obtain a “title only” from your state listing J.J. Best Bank as lien holder. The state in which you reside may also charge late fees/penalty fees if the vehicle is not registered/titled in a timely manner.',
    ],
  },
  {
    id: 39,
    question: 'Who is to be listed as owner on the title?',
    category: 'Car titles',
    answer_items: [
      'Only the person listed on our loan documents can be listed as owner on the title. You cannot list anyone else on the title, such as a spouse, unless they are on the loan agreement. Do not make a mistake as it creates a lot of confusion for you, the borrower.',
    ],
  },
  {
    id: 40,
    question: 'Can I purchase a car with an out of state title?',
    category: 'Car titles',
    answer_items: [
      'Yes, we can finance a dealer sale or a private party sale out of state.',
    ],
  },
  {
    id: 41,
    question:
      'Yes, we can finance a dealer sale or a private party sale out of state.',
    category: 'Car titles',
    answer_items: [
      "The JJ Best lienholder's address is: J.J. Best & Co. 60 N. Water Street New Bedford, MA 02740",
      'AZ: 043283632',
      'CA: Z62',
      'CO: E0432836320001',
      'FL: 228381777',
      'GA: 001108869403',
      'IN: 0432836320001',
      'LA: ENJB',
      'MA: VJJ',
      'MD: 0422',
      'MI: LH010556',
      'NC: 36390768',
      'NE: 0882517',
      'NY: 71273',
      'NJ: 480-089-197-02740-0',
      'NV: JJ0001',
      'PA: 04328363201',
      'SC: JJB7787',
      'SD: 043283632',
      'TX: 04328363200',
      'VA: FR3',
    ],
  },
  {
    id: 42,
    question: 'Do you offer insurance quotes?',
    category: 'Insurance',
    answer_items: [
      'Yes, call our J.J. Best Insurance Agent at 1-888-4BEST-IN or 1-888-423-7846 for a quote on our low cost collector car insurance and program information.',
    ],
  },
  {
    id: 43,
    question: 'What type of insurance do you usually require?',
    category: 'Insurance',
    answer_items: [
      'J.J. Best Bank & Co. typically requires guaranteed value, full coverage, liability and collision insurance.',
    ],
  },
  {
    id: 44,
    question: 'Do I need insurance to receive the loan proceeds?',
    category: 'Insurance',
    answer_items: [
      'Yes, you do need to insure the vehicle before you receive your loan proceeds. Please provide J.J. Best Banc with a binder or other proof of insurance listing J.J. Best Banc – BEST BANC BUILDING 60 N. Water St. New Bedford, MA 02740 as Loss Payee. J.J. Best Banc is also proud to offer classic car insurance that is available in most states.',
    ],
  },
  {
    id: 45,
    question: 'What is an insurance binder?',
    category: 'Insurance',
    answer_items: [
      'An insurance binder is a document that states your insurance is temporarily in effect. Because of the coverage will expire by a specific date, a permanent policy must be obtained before the expiration date.',
    ],
  },
  {
    id: 46,
    question:
      "Why do you need a 'declaration sheet' from my insurance company for cars that I drive on a daily basis?",
    category: 'Insurance',
    answer_items: [
      'J.J. Best Banc & Co. insures across the United States and offers guaranteed value as well as full coverage insurance. Most states have state-mandated insurance requirements. For this reason, we need a declaration sheet to mirror the liabilities that you carry on the cars that you drive every day.',
    ],
  },
  {
    id: 47,
    question:
      'Even though my wife and/or dependent teenager will not drive the classic car why do I need to show the cars they drive and their insurance?',
    category: 'Insurance',
    answer_items: [
      'J.J. Best Banc & Co. needs to ensure that each driver has a daily car available to them so the classic car is not being used as an additional daily driver.',
    ],
  },
];
