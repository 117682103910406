import { useEffect, useState } from "react";
import {
  Container,
  Text,
  Flex,
  Box,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { getApplication } from "../../../../../graphql/queries"
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_jjbest_stepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";
import protectedRoute from "../../../../../utils/protectedRoute"

const ContactUs = (props) => {
  const { userInfo } = props;
  const { id } = useParams();
  const [application, setApplication] = useState([])
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 4,
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }
    }
    handleFetchApplication()
  }, [ userInfo, id]);

  return userInfo === null || userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar  userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>

              
                <Container maxW="5xl">
                  <Box
                    bg="bg.surface"
                    boxShadow="sm"
                    borderRadius="lg"
                    p={{ base: '4', md: '6' }}
                  >
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '5', md: '6' }}
                      justify="space-between"
                    >
                      <Stack spacing="1">
                        <Text textStyle="lg" fontWeight="medium">
                        Placeholder for Loan Officer appointment
                        </Text>
                        <Text textStyle="sm" color="fg.muted">
                          {JSON.stringify(application)}
                        </Text>
                      </Stack>
                      <Box>
                        
                      </Box>
                    </Stack>
                  </Box>
                </Container>
              </Box>

             
        
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(ContactUs);
