export const buyers_guide_data = [
  {
    "id": 1,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  },
  {
    "id": 2,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Suspendisse sed justo ut eros lobortis sodales vel sit amet lacus."
  },
  {
    "id": 3,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Cras sollicitudin urna tincidunt arcu finibus tempus."
  },
  {
    "id": 4,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Sed accumsan velit imperdiet pharetra placerat."
  },
  {
    "id": 5,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Quisque volutpat leo id ornare congue."
  },
  {
    "id": 6,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Maecenas pharetra tortor sit amet ex viverra semper."
  },
  {
    "id": 7,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Praesent sollicitudin ipsum non justo porta, vel venenatis lectus sodales."
  },
  {
    "id": 8,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Sed ultricies tellus sit amet lacus vestibulum bibendum."
  },
  {
    "id": 9,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Praesent cursus turpis non dolor lobortis facilisis."
  },
  {
    "id": 10,
    "ymm": "1969-bmw-2000",
    "buyers_advice": "Suspendisse mattis ex in lorem volutpat, eget molestie risus pharetra."
  },
  {
    "id": 11,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  },
  {
    "id": 12,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Suspendisse sed justo ut eros lobortis sodales vel sit amet lacus."
  },
  {
    "id": 13,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Cras sollicitudin urna tincidunt arcu finibus tempus."
  },
  {
    "id": 14,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Sed accumsan velit imperdiet pharetra placerat."
  },
  {
    "id": 15,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Quisque volutpat leo id ornare congue."
  },
  {
    "id": 16,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Maecenas pharetra tortor sit amet ex viverra semper."
  },
  {
    "id": 17,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Praesent sollicitudin ipsum non justo porta, vel venenatis lectus sodales."
  },
  {
    "id": 18,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Sed ultricies tellus sit amet lacus vestibulum bibendum."
  },
  {
    "id": 19,
    "ymm": "1970-bmw-2000",
    "buyers_advice": "Praesent cursus turpis non dolor lobortis facilisis."
  },
  {
    "id": 20,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  },
  {
    "id": 21,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Suspendisse sed justo ut eros lobortis sodales vel sit amet lacus."
  },
  {
    "id": 22,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Cras sollicitudin urna tincidunt arcu finibus tempus."
  },
  {
    "id": 23,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Sed accumsan velit imperdiet pharetra placerat."
  },
  {
    "id": 24,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Quisque volutpat leo id ornare congue."
  },
  {
    "id": 25,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Maecenas pharetra tortor sit amet ex viverra semper."
  },
  {
    "id": 26,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Praesent sollicitudin ipsum non justo porta, vel venenatis lectus sodales."
  },
  {
    "id": 27,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Sed ultricies tellus sit amet lacus vestibulum bibendum."
  },
  {
    "id": 28,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Praesent cursus turpis non dolor lobortis facilisis."
  },
  {
    "id": 29,
    "ymm": "1975-jaguar-xj-series-ii",
    "buyers_advice": "Suspendisse mattis ex in lorem volutpat, eget molestie risus pharetra."
  },
  {
    "id": 30,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  },
  {
    "id": 31,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Suspendisse sed justo ut eros lobortis sodales vel sit amet lacus."
  },
  {
    "id": 32,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Cras sollicitudin urna tincidunt arcu finibus tempus."
  },
  {
    "id": 33,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Sed accumsan velit imperdiet pharetra placerat."
  },
  {
    "id": 34,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Quisque volutpat leo id ornare congue."
  },
  {
    "id": 35,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Maecenas pharetra tortor sit amet ex viverra semper."
  },
  {
    "id": 36,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Praesent sollicitudin ipsum non justo porta, vel venenatis lectus sodales."
  },
  {
    "id": 37,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Sed ultricies tellus sit amet lacus vestibulum bibendum."
  },
  {
    "id": 38,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Praesent cursus turpis non dolor lobortis facilisis."
  },
  {
    "id": 39,
    "ymm": "1976-jaguar-xj-series-ii",
    "buyers_advice": "Suspendisse mattis ex in lorem volutpat, eget molestie risus pharetra."
  }
]