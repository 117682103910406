import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Divider,
} from '@chakra-ui/react';
import { generateClient } from 'aws-amplify/api';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import protectedRoute from '../../../../utils/protectedRoute';
import { articleByCategory } from '../../../../graphql/queries';
import YMMPost from '../../../../components/buyers-guides/YMMPost';
import LoanCalculator from '../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';

const BuyersGuides = props => {
  const { userInfo } = props;
  const [articles, setArticles] = useState([]);
  const [featuredArticle, setFeaturedArticle] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleFetchArticles = async () => {
      if (userInfo) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: articleByCategory,
            variables: { category: 'ymm' },
          });
          setArticles(result.data.articleByCategory.items);
          setLoading(false);
          handleFindFeaturedArticle(result.data.articleByCategory.items);
        } catch (err) {
          console.log('error in fetchArticle: ', err);
        }
      }
    };
    const handleFindFeaturedArticle = async data => {
      const filteredResult = data.filter(item => item.isFeatured === 'Yes');
      setFeaturedArticle(filteredResult.pop());
    };
    handleFetchArticles();
  }, [userInfo]);

  return loading || articles === null || undefined ? (
    <div></div>
  ) : (
    <LandingLayoutJJBest>
      <Container py="2" flex="1" maxW={'8xl'}>
        <Box bg="bg.surface">
          <Box bg="bg.accent.default" color="fg.accent.default">
            <Container
              pt={{ base: '16', md: '24' }}
              pb={{ base: '32', md: '48' }}
              maxW={'7xl'}
            >
              <Stack spacing={{ base: '8', md: '10' }} align="center">
                <Stack spacing={{ base: '4', md: '6' }} textAlign="center">
                  <Stack spacing="4">
                    <Heading size={{ base: 'md', md: 'lg' }}>
                      J.J. Best Buyer's Guides
                    </Heading>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Box>
          <Container
            pb={{ base: '16', md: '24' }}
            mt={{ base: '-16', md: '-24' }}
            maxW={'7xl'}
          >
            <Stack spacing={{ base: '16', md: '24' }}>
              <Stack spacing={{ base: '12', md: '16' }}>
                {/* <BlogPost
                  article={featuredArticle}
                  isHero
                  unsignedUrl={
                    featuredArticle.articleImages.items[0].articleImageFile
                  }
                /> */}
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  gap={{ base: '12', lg: '8' }}
                >
                  {articles.map(article => (
                    <YMMPost
                      key={article.id}
                      article={article}
                      unsignedUrl={
                        article.articleImages.items[0].articleImageFile
                      }
                    />
                  ))}
                </SimpleGrid>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </Container>
      <Divider />
      <LoanCalculator />
      <JJBestFooter />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(BuyersGuides);
