import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Box,
  Text,
} from '@chakra-ui/react';
import { boats } from '../../data/_jjbest_boatExamples';
import BoatCard from './BoatCard';

const BoatExamples = () => (
  <Box as="section" py={{ base: '16', md: '24' }}>
    <Container maxW={'8xl'} p="5" bgColor="gray.50">
      <Stack spacing="3" mb="10">
        <Heading size={{ base: 'md', md: 'xl' }}>
          Boats We Typically Finance
        </Heading>
      </Stack>
      <Stack spacing={{ base: '12', md: '16' }}>
        <Stack spacing={{ base: '4', md: '5' }} maxW="3xl">
          <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
          Unlike many lenders who restrict financing to boats under 20 years old, we specialize in providing financial solutions for boats of any age. This unique approach allows us to cater to customers who may be passionate about classic or vintage vessels.
          </Text>
        </Stack>
        <SimpleGrid
          columns={{ base: 1, sm: 2, lg: 5 }}
          gap={{ base: '8', lg: '12' }}
        >
          {boats.map(boat => (
            <BoatCard key={boat.id} boat={boat} />
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
);

export default BoatExamples;
