import {
  Container,
  Heading,
  Flex,
  Box,
  Stack,
  HStack,
  Button,
  Image,
  Link,
} from '@chakra-ui/react';
import { HiChevronRight } from 'react-icons/hi';
import homeimage from '../../assets/photos/jjbesthero.jpg';

const JJBestHero = () => (
  <>
    <Container
      bg="gray.800"
      as="header"
      minH="140px"
      position="relative"
      maxW="9xl"
    >
      <Box py="40" position="relative" zIndex={1}>
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
          color="white"
        >
          <Box maxW="xl">
            <Heading as="h1" size="xl" fontWeight="extrabold">
              Don&apos;t Just Admire It. Own It.
            </Heading>
            {/* <Heading as="h1" size="xl" fontWeight="extrabold">
            Always Low Rates and Long Terms
            </Heading> */}

            <Heading size="md" fontWeight="normal" color="white" mt="10" mb="5">
              Our collector car experts get you approved in minutes and ensure the lowest monthly payments
            </Heading>

            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              mt="10"
              spacing="4"
            >
              <Link href="/jjbest/application/app-start">
                <Button
                  // as="a"
                  bgColor="other.400"
                  colorScheme="blue"
                  px="8"
                  mb="5"
                  rounded="full"
                  size="lg"
                  fontSize="md"
                  fontWeight="bold"
                  type="submit"
                >
                  Get pre-approved
                </Button>
              </Link>
              <HStack
                // as="a"
                transition="background 0.2s"
                justify={{ base: 'center', md: 'flex-start' }}
                href="tel:8008721965"
                color="white"
                rounded="full"
                fontWeight="bold"
                px="6"
                py="3"
                _hover={{ bg: 'whiteAlpha.300' }}
              >
                <span>Call 1-800-USA-1965</span>
                <HiChevronRight />
              </HStack>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Image
            src={homeimage}
            alt="Marymont Labs"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="top bottom"
            position="absolute"
          />
          <Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
        </Box>
      </Flex>
    </Container>
  </>
);

export default JJBestHero;
