import React from "react";
import { useNavigate } from 'react-router-dom'
import { Box, Img } from "@chakra-ui/react";
import logo from './jjbest-footer-logo.svg';

const FooterLogo = (props) => {
  const navigate = useNavigate();

  function handleHomeRedirect() {
    navigate('/jjbest/classic-car-financing/no-nav')
  }
  return (
    <Box as="button" bg="transparent" px={4} h={8} {...props} onClick={handleHomeRedirect}>
       <Img
          src={logo} 
          width={200}
          height={150}
          alt="JJ Best"
          layout='intrinsic'
       />
    </Box>
  );
}

export default FooterLogo