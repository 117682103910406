import { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import SectionHeader from '../../widgets/SectionHeader';
import { buyers_guide_data } from '../../data/_sampleBuyersGuideData';

const _ = require('lodash');

const BuyersGuideContent = () => {
  const ymm = useParams();

  const [buyersGuideData, setBuyersGuideData] = useState([]);
  const [ymmString, setYmmString] = useState("");

  useEffect(() => {
    const handleFetchData = async () => {
      if (ymm) {
        const result = _.filter(buyers_guide_data, function (item) {
          return item.ymm === ymm.ymm;
        });
        setBuyersGuideData(result);
      }
    };
    const handleYmmString = async () => {
      if(ymm) {
        const ymmArray = ymm.ymm.split("-")
        setYmmString(ymmArray.join(" "));
      }
    }

    handleFetchData();
    handleYmmString()
  }, [ymm]);

  return (
    <Container maxW={'8xl'} p="5" m="10">
      <Box p="5"  bgColor="gray.50">
      <Flex
         gap={{ base: '5', md: '10' }}
        direction={{ base: 'column', md: 'row' }}
       
      >
        <SectionHeader
          flex="1"
          maxW="xl"
          headline={`Expert buyers advice for ${ymmString}`}
          description="We've currated experts to help you make informed buying decisions"
          // subdescription="#3 vehicles drive and run well but are not used for daily transportation. The casual passerby will not find any visual flaws, but these vehicles might have some incorrect parts."
        />
        <SimpleGrid columns={1} gap="8" flex="1">
          {buyersGuideData &&
            buyersGuideData.map(item => (
              <Stack gap="0" pb="4" borderBottomWidth="1px" key={item.id}>
                {/* <Text textStyle="4xl" fontWeight="semibold">
                  {formatter.format(
                    parseInt(item.average_value, 10).toFixed(0)
                  )}
                </Text> */}
                <Text>
                  {item.buyers_advice} 
                </Text>
              </Stack>
            ))}
        </SimpleGrid>
      </Flex>
      </Box>
      
    </Container>
  );
};

export default BuyersGuideContent;
