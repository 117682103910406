import { Container, Text, Heading, Stack, VStack } from '@chakra-ui/react';

const AboutJJBestContent = () => {

  return (
    <Container maxW={'8xl'} p="5">
      <Stack align={['left']}>
        <Heading
          as="h2"
          size="xl"
          lineHeight="short"
          fontWeight="bold"
          color="secondary.600"
          maxW={{
            base: 'unset',
            lg: '800px',
          }}
        >
          All For the Love of Classic Cars and Boats
        </Heading>
      </Stack>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Text as="p" fontSize="lg">
        J.J. Best Banc & Company also known as J.J. Best Banc, was founded in 1993 in Chatham, MA. The principal had been working in the financial industry on Wall Street for many years and owned a mortgage company before starting J.J. Best Banc & Co. Being a longtime collector of classic cars, he recognized that financial firms were not serving the classic and collector car industry and saw an opportunity to fill this gap with low rate loan products.
        </Text>
        <br></br>
    
        <Text as="p" fontSize="lg">
        In 2003, from Chatham the operation moved to a large Mill building in New Bedford, MA. The commitment to a consistent marketing campaign to classic car dealers and being very active in car auctions nationwide (like the 8 day Auburn, Indiana Car) nurtured J.J. Best’s early growth. Soon enough more loan officers were hired to facilitate the incoming loan applications. A family member also came on board at J.J. Best Banc and focused his efforts on contemporary marketing initiatives.
        </Text>
        <br></br>
        <Text as="p" fontSize="lg">
        In 2005, J.J. Best Banc sold the New Bedford office building and moved to their present location a 1832 Double Bank Building which has housed Banks since 1832. Over the last 20 years, J.J. Best Banc has started the J.J. Best Banc Insurance Agency to complement their loan activity as well as a title company to speed up the loan funding process. The company also added other loan and insurance services for higher price products – RVs, boats, yachts, and airplanes. J.J. Best Banc has evolved into a large company where customer service is paramount to their success. Every customer is valued and serviced by professional loan officers and admin staff to meet their needs. This is why J.J. Best Banc is the oldest and largest classic car financier today.
        </Text>
        <br></br>
        <Heading
          as="h2"
          size="xl"
          lineHeight="short"
          fontWeight="bold"
          color="secondary.600"
          maxW={{
            base: 'unset',
            lg: '800px',
          }}
        >
          Who We Are
        </Heading>
        <Text as="p" fontSize="lg">
        With any financial firm, privacy and confidentiality is important to customers applying for a loan. At J.J. Best Banc we take this very seriously and understand the customer sensitivity in running a loan application.
        </Text>
        <br></br>
        <Text as="p" fontSize="lg">
        At J.J. Best Banc we have set a high standard to customer service knowing each applicant wants to be approved and funded. We know every detail matters and we try very hard to make sure we have done our best to meet their needs and our lending requirements. Each loan officer is assigned to an applicant or dealer/broker for the customer to get to know our staff, be guided thru the process and be comfortable to ask any questions along the way.
        </Text>
        <br></br>
        <Text as="p" fontSize="lg">
        Our loan officers work diligent every day of the week for customers to have access to them. Are we considered classic car financial industry experts? Yes. We have over 50,000 hours combined financial experience and have been challenged with every roadblock for funding to occur. Having an affiliate insurance and title diligent staff makes our process even quicker. To say it simply—J.J. Best Banc is made up of a small family of committed professionals (and classic car enthusiasts) to help fund a classic car or boat or plane and have our customers dreams come true.
        </Text>

        
      </VStack>
    </Container>
  );
};

export default AboutJJBestContent;