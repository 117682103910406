/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplicationVersion = /* GraphQL */ `
  mutation CreateApplicationVersion(
    $input: CreateApplicationVersionInput!
    $condition: ModelApplicationVersionConditionInput
  ) {
    createApplicationVersion(input: $input, condition: $condition) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplicationVersion = /* GraphQL */ `
  mutation UpdateApplicationVersion(
    $input: UpdateApplicationVersionInput!
    $condition: ModelApplicationVersionConditionInput
  ) {
    updateApplicationVersion(input: $input, condition: $condition) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplicationVersion = /* GraphQL */ `
  mutation DeleteApplicationVersion(
    $input: DeleteApplicationVersionInput!
    $condition: ModelApplicationVersionConditionInput
  ) {
    deleteApplicationVersion(input: $input, condition: $condition) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      clientId
      tagName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      clientId
      tagName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      clientId
      tagName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      title
      author
      metaDescription
      synopsis
      category
      url
      isFeatured
      articleUpdateTimeStamp
      articleImages {
        nextToken
        __typename
      }
      articleTags {
        nextToken
        __typename
      }
      articleSections {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      title
      author
      metaDescription
      synopsis
      category
      url
      isFeatured
      articleUpdateTimeStamp
      articleImages {
        nextToken
        __typename
      }
      articleTags {
        nextToken
        __typename
      }
      articleSections {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      title
      author
      metaDescription
      synopsis
      category
      url
      isFeatured
      articleUpdateTimeStamp
      articleImages {
        nextToken
        __typename
      }
      articleTags {
        nextToken
        __typename
      }
      articleSections {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArticleTag = /* GraphQL */ `
  mutation CreateArticleTag(
    $input: CreateArticleTagInput!
    $condition: ModelArticleTagConditionInput
  ) {
    createArticleTag(input: $input, condition: $condition) {
      id
      articleID
      articleTagName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticleTag = /* GraphQL */ `
  mutation UpdateArticleTag(
    $input: UpdateArticleTagInput!
    $condition: ModelArticleTagConditionInput
  ) {
    updateArticleTag(input: $input, condition: $condition) {
      id
      articleID
      articleTagName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticleTag = /* GraphQL */ `
  mutation DeleteArticleTag(
    $input: DeleteArticleTagInput!
    $condition: ModelArticleTagConditionInput
  ) {
    deleteArticleTag(input: $input, condition: $condition) {
      id
      articleID
      articleTagName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArticleSection = /* GraphQL */ `
  mutation CreateArticleSection(
    $input: CreateArticleSectionInput!
    $condition: ModelArticleSectionConditionInput
  ) {
    createArticleSection(input: $input, condition: $condition) {
      id
      articleID
      sectionHeader
      sectionContent
      sectionContentMarkup
      sectionOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticleSection = /* GraphQL */ `
  mutation UpdateArticleSection(
    $input: UpdateArticleSectionInput!
    $condition: ModelArticleSectionConditionInput
  ) {
    updateArticleSection(input: $input, condition: $condition) {
      id
      articleID
      sectionHeader
      sectionContent
      sectionContentMarkup
      sectionOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticleSection = /* GraphQL */ `
  mutation DeleteArticleSection(
    $input: DeleteArticleSectionInput!
    $condition: ModelArticleSectionConditionInput
  ) {
    deleteArticleSection(input: $input, condition: $condition) {
      id
      articleID
      sectionHeader
      sectionContent
      sectionContentMarkup
      sectionOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArticleImage = /* GraphQL */ `
  mutation CreateArticleImage(
    $input: CreateArticleImageInput!
    $condition: ModelArticleImageConditionInput
  ) {
    createArticleImage(input: $input, condition: $condition) {
      id
      articleID
      articleImageFile
      articleImageObject {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticleImage = /* GraphQL */ `
  mutation UpdateArticleImage(
    $input: UpdateArticleImageInput!
    $condition: ModelArticleImageConditionInput
  ) {
    updateArticleImage(input: $input, condition: $condition) {
      id
      articleID
      articleImageFile
      articleImageObject {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticleImage = /* GraphQL */ `
  mutation DeleteArticleImage(
    $input: DeleteArticleImageInput!
    $condition: ModelArticleImageConditionInput
  ) {
    deleteArticleImage(input: $input, condition: $condition) {
      id
      articleID
      articleImageFile
      articleImageObject {
        bucket
        region
        key
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
