import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';
import { generateClient } from 'aws-amplify/api';
import { articleByCategory } from '../../graphql/queries';
import BlogPost from '../../components/articles/BlogPost';


const LatestArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleFetchArticles = async () => {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: articleByCategory,
            variables: { category: 'blog' },
          });
          setArticles(result.data.articleByCategory.items);
          setLoading(false);
        } catch (err) {
          console.log('error in fetchArticle: ', err);
        }
    };
    handleFetchArticles();
  }, []);

  return (loading || articles === null || undefined ? 
    <div></div> :
      <Box bg="bg.surface" p="5" m="5">
      <Container
            pb={{ base: '16', md: '24' }}
            mt={{ base: '-16', md: '-24' }}
            maxW={'8xl'}
          >
            <Stack spacing="3" mb="10">
      <Heading size={{ base: 'md', md: 'xl' }}>Latest Articles</Heading>
      </Stack>
            <Stack spacing={{ base: '16', md: '24' }}>
              <Stack spacing={{ base: '12', md: '16' }}>
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  gap={{ base: '12', lg: '8' }}
                >
                  {articles.slice(1, 4).map(article => (
                    <BlogPost
                      key={article.id}
                      article={article}
                      unsignedUrl={article.articleImages.items[0].articleImageFile}
                    />
                  ))}
                </SimpleGrid>
              </Stack>
            </Stack>
          </Container>
      </Box>
  );
};

export default LatestArticles;
