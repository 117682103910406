import sailboat from "../assets/photos/sail-boat-social.jpg";
import chriscraft from "../assets/photos/wooden-boat.jpg";

export const boats = [
  {
    id: 1,
    name: 'Sailboats',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nibh diam, dapibus id nunc in, dignissim congue mauris.',
    image: sailboat,
  },
  {
    id: 2,
    name: 'Wooden Cruisers',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nibh diam, dapibus id nunc in, dignissim congue mauris.',
    image: chriscraft
  },
  {
    id: 3,
    name: 'Type 3',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nibh diam, dapibus id nunc in, dignissim congue mauris.',
    image: sailboat,
  },
  {
    id: 4,
    name: 'Type 4',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nibh diam, dapibus id nunc in, dignissim congue mauris.',
    image: chriscraft,
  },
  {
    id: 5,
    name: 'Type 5',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nibh diam, dapibus id nunc in, dignissim congue mauris.',
    image: sailboat,
  },
];