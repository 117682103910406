export const validateZip = async (zip) => {
  var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip.toString());
  return isValidZip;
};

export const validateEmail = async (emailAddress) => {
  var isValidEmail = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(emailAddress.toString());
  return isValidEmail;
};  

export const validateRequiredField = async (inputtx) => {
  if(inputtx === undefined || inputtx.length === 0) {
      return false
  }
  return true;
};  

export const validateVIN = async (vin) => {
  var isValidVIN = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/.test(vin.toString());
  return isValidVIN;
};  

export const validateNumeric = async (inputtx) => {
  var numbers = /^[0-9]+$/;
    if(inputtx.match(numbers)){
      return true;
    } else {
      return false;
    }
};  

export const validateYearLength = async (inputtx) => {
  const yearMinLength = 4;
  const yearMaxLength = 4;
    if(inputtx.length === yearMaxLength && inputtx.length === yearMinLength){
      return true;
    } else {
      return false;
    }
};  

export const validateMonth = async (inputtx) => {
  console.log("inputtx: ", inputtx);
  //var numbers = /(^0?[1-9]$)|(^1[0-2]$)/;
  var validMonthsOfYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    if(validMonthsOfYear.includes(inputtx)){
      return true;
    } else {
      return false;
    }
}; 

export const validateDay = async (inputtx) => {
  var validDaysOfMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    if(validDaysOfMonth.includes(inputtx)){
      return true;
    } else {
      return false;
    }
};

export const validateYear = async (inputtx) => {
  var numbers = /^(194[2-9]|19[5-9]\d|200\d|200[0-4])$/;
    if(inputtx.toString().match(numbers)){
      return true;
    } else {
      return false;
    }
};

export const validateStateAbbreviation = async (inputtx) => {
  var states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 
      'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 
      'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 
      'WV', 'WY'];
    if(states.includes(inputtx.toUpperCase())){
      return true;
    } else {
      return false;
    }
};