// import { Button } from '@/components/ui/button'
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  FormControl,
  Wrap,
  WrapItem,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const RelatedVehicles = props => {
  const { articleTags } = props;
  const navigate = useNavigate();

  function handleSearchTagClick(e, tag) {
    const tagArray = tag.articleTagName.split(" ");
    const urlString = tagArray.join("-")
    e.preventDefault();
    navigate(`/jjbest/specialty-financing/buyers-guides/searchbytag/${urlString}`);
  }

  return (
      <Box p="5">
        <Card
          variant="elevated"
          boxShadow="lg"
          position="relative"
          overflow="hidden"
        >
          <CardHeader>
            <Text fontSize="xl" fontWeight="bold">
              More on this topic
            </Text>
          </CardHeader>
          <CardBody gap="8">
            <Flex h="full" direction="column">
              <Stack spacing={4}>
                <FormControl id="taglist">
                  <Wrap width="full" spacing="2">
                    {articleTags &&
                      articleTags.map(tag => {
                        return (
                          <WrapItem key={tag.id}>
                            <Box
                              as="button"
                              key={tag.id}
                              borderRadius="sm"
                              bg="secondary.700"
                              alignItems="center"
                              justifyContent="center"
                              color="white"
                              px={4}
                              py={1}
                              h={8}
                              onClick={e => handleSearchTagClick(e, tag)}
                            >
                              {tag.articleTagName}
                            </Box>
                          </WrapItem>
                        );
                      })}
                  </Wrap>
                </FormControl>
              </Stack>
            </Flex>
          </CardBody>
        </Card>
      </Box>
  );
};

export default RelatedVehicles;
