// import { Button } from '@/components/ui/button'
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const YMMQuote = () => {
  const navigate = useNavigate();
  function handleAppNavigation() {
    navigate('/jjbest/application/app-start');
  }
  return (
    <Box p="5">
      <Card
        variant="elevated"
        boxShadow="lg"
        position="relative"
        overflow="hidden"
      >
        <CardHeader>
          <Text fontSize="xl" fontWeight="bold">
            Prequalify in five minutes or less!
          </Text>
        </CardHeader>
        <CardBody gap="8">
          <Text fontSize="md" fontWeight="semibold">
            Call us at 1-800-USA-1965, or fill out our online application form.
          </Text>
        </CardBody>
        <CardFooter>
          <Button
            bgColor="primary.900"
            color="white"
            width="full"
            onClick={handleAppNavigation}
          >
            Get a quote
          </Button>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default YMMQuote;
