import {
  Stack,
  CircularProgress,
  Box,
  Divider,
  // useColorModeValue as mode,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import LandingLayoutJJBest from '../../../../../components/jjbest/LandingLayoutJJBest';
import LoanCalculator from '../../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../../components/jjbest/JJBestFooter';
import ArticleHero from '../../../../../components/heros/ArticleHero';
import { articleByUrl } from '../../../../../graphql/queries';
import GetArticleSectionItems from '../../../../../components/articles/GetArticleSectionItems';
import YMMQuote from "../../../../../components/buyers-guides/YMMQuote";
import RelatedVehicles from "../../../../../components/buyers-guides/RelatedVehicles";
import ValuationContent from '../../../../../components/jjbest/ValuationContent';
import BuyersGuideContent from '../../../../../components/jjbest/BuyersGuideContent';

const _ = require('lodash');

const BuyersGuideSlug = props => {
  const { userInfo } = props;
  const { ymm } = useParams();
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [articleTags, setArticleTags] = useState([]);
  const [thisArticleSectionData, setThisArticleSectionData] = useState([]);
  // const color1 = mode('gray.50', 'gray.800');

  useEffect(() => {
    const handleFetchArticle = async () => {
      if (ymm) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: articleByUrl,
            variables: { url: ymm },
          });
          setArticle(result.data.articleByUrl.items[0]);
          handleSortedArticleTags(
            result.data.articleByUrl.items[0].articleTags.items
          );
          const sectionResult = await result.data.articleByUrl.items[0]
            .articleSections.items;
          setThisArticleSectionData(sectionResult);
          setLoading(false);
        } catch (err) {
          console.log('error in fetchArticle: ', err);
        }
      }
    };
    const handleSortedArticleTags = async data => {
      if (ymm) {
        const sortedArticleTagData = _.sortBy(data, ['articleTags'], ['asc']);
        setArticleTags(sortedArticleTagData);
        // setLoading(false);
      }
    };
    handleFetchArticle();
  }, [userInfo, ymm]);

  return loading ? (
    <CircularProgress />
  ) : (
    <LandingLayoutJJBest>
      <Stack spacing={4}>
        {article === null || undefined ? (
          <div></div>
        ) : (
          <ArticleHero article={article} />
        )}

        <Box
          //bg={mode('gray.100', 'gray.800')}
          as="section"
          py={{
            base: '12',
            md: '24',
          }}
        >
          <Box
            maxW={{
              base: 'xl',
              md: '7xl',
            }}
            mx="auto"
            px={{
              base: '6',
              md: '8',
            }}
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              spacing={{
                base: '12',
                xl: '20',
              }}
              //align="center"
            >
              <Box flex="1">
                <GetArticleSectionItems
                  articleSectionData={thisArticleSectionData}
                />
              </Box>
              <Box
                maxW="450px"
                flex="1"
                h={{
                  base: '240px',
                  md: '400px',
                }}
                flexShrink={0}
                align="center"
                //bg={mode('gray.50', 'gray.800')}
              >
                <Stack spacing="4">
                <YMMQuote />
                
                <RelatedVehicles articleTags={articleTags} />
                </Stack>
                
          
              </Box>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Divider />
      <ValuationContent />
      <BuyersGuideContent />
      <LoanCalculator />
      <JJBestFooter />
    </LandingLayoutJJBest>
  );
};

export default BuyersGuideSlug;
