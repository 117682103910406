import React, { useEffect } from 'react';
import JJBestHeroCarousel from '../../../../components/jjbest/JJBestHeroCarousel';
import JJBestBoatContent from '../../../../components/jjbest/JJBestBoatContent';
import LoanCalculator from '../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';
import JJBestDisclosures from "../../../../components/jjbest/JJBestDisclosures";
import JJBestFeatures from '../../../../components/jjbest/JJBestFeatures';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import JJBestTestimonials from "../../../../components/jjbest/JJBestTestamonials";
import BoatExamples from "../../../../components/jjbest/BoatExamples"
import LatestArticles from "../../../../components/jjbest/LatestArticles";
import protectedRoute from '../../../../utils/protectedRoute';

const JJBestBoatFinancingNoNav = props => {
  const { userInfo } = props;

  useEffect(() => {}, [userInfo]);

  return (
    <LandingLayoutJJBest>
      <JJBestHeroCarousel />
      <JJBestBoatContent />
      <JJBestFeatures />
      <BoatExamples />
      <LoanCalculator />
      <JJBestTestimonials />
      <LatestArticles />
      <JJBestFooter />
      <JJBestDisclosures />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(JJBestBoatFinancingNoNav);
