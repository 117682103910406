export const valuation_data = [
  {
    "ymm": "1969-bmw-2000",
    "year": 1969,
    "make": "BMW",
    "model": 2000,
    "submodel": "Base",
    "body_engine": "4dr Sedan 4-cyl. 1990cc/114hp 1bbl Solex",
    "condition": 3,
    "average_value": 10700
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1967,
    "make": "BMW",
    "model": 2000,
    "submodel": "Base",
    "body_engine": "4dr Sedan 4-cyl. 1990cc/113hp 1bbl Solex",
    "condition": 3,
    "average_value": 10100
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1968,
    "make": "BMW",
    "model": 2000,
    "submodel": "Base",
    "body_engine": "4dr Sedan 4-cyl. 1990cc/114hp 1bbl Solex",
    "condition": 3,
    "average_value": 10200
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1966,
    "make": "BMW",
    "model": 2000,
    "submodel": "Base",
    "body_engine": "4dr Sedan 4-cyl. 1990cc/113hp 1bbl Solex",
    "condition": 3,
    "average_value": 10100
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1969,
    "make": "BMW",
    "model": 2800,
    "submodel": "Base",
    "body_engine": "4dr Sedan 6-cyl. 2788cc/192hp 2x2bbl",
    "condition": 3,
    "average_value": 18600
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1969,
    "make": "BMW",
    "model": 2500,
    "submodel": "Base",
    "body_engine": "4dr Sedan 6-cyl. 2494cc/192hp 2x2bbl Zenith",
    "condition": 3,
    "average_value": 17700
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1969,
    "make": "BMW",
    "model": 2002,
    "submodel": "Base",
    "body_engine": "2dr Sedan 4-cyl. 1900cc/114hp 1bbl Solex",
    "condition": 3,
    "average_value": 23600
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1966,
    "make": "BMW",
    "model": "2002C",
    "submodel": "Base",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/135hp 1bbl Solex",
    "condition": 3,
    "average_value": 17700
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1967,
    "make": "BMW",
    "model": "2002C",
    "submodel": "Base",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/135hp 1bbl Solex",
    "condition": 3,
    "average_value": 17700
  },
  {
    "ymm": "1969-bmw-2000",
    "year": 1968,
    "make": "BMW",
    "model": 2002,
    "submodel": "Base",
    "body_engine": "2dr Sedan 4-cyl. 1900cc/114hp 1bbl Solex",
    "condition": 3,
    "average_value": 23100
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1970,
    "make": "BMW",
    "model": 2000,
    "submodel": "Base",
    "body_engine": "4dr Sedan Unknown",
    "condition": 3,
    "average_value": 10700
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1970,
    "make": "BMW",
    "model": 2800,
    "submodel": "Base",
    "body_engine": "4dr Sedan 6-cyl. 2788cc/192hp 2x2bbl",
    "condition": 3,
    "average_value": 18600
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1970,
    "make": "BMW",
    "model": 2500,
    "submodel": "Base",
    "body_engine": "4dr Sedan 6-cyl. 2494cc/192hp 2x2bbl Zenith",
    "condition": 3,
    "average_value": 23600
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1971,
    "make": "BMW",
    "model": 2002,
    "submodel": "Base",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/135hp 1bbl Solex",
    "condition": 3,
    "average_value": 17700
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1975,
    "make": "BMW",
    "model": 2500,
    "submodel": "Base",
    "body_engine": "4dr Sedan 6-cyl. 2494cc/192hp 2x2bbl Zenith",
    "condition": 3,
    "average_value": 21100
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1974,
    "make": "BMW",
    "model": 2002,
    "submodel": "Base",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/135hp 1bbl Solex",
    "condition": 3,
    "average_value": 22200
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1973,
    "make": "BMW",
    "model": 2002,
    "submodel": "Base",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/100hp 1bbl",
    "condition": 3,
    "average_value": 24700
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1973,
    "make": "BMW",
    "model": 2002,
    "submodel": "Turbo",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/170hp F1 Turbo",
    "condition": 3,
    "average_value": 94400
  },
  {
    "ymm": "1970-bmw-2000",
    "year": 1976,
    "make": "BMW",
    "model": 2002,
    "submodel": "Base",
    "body_engine": "2dr Coupe 4-cyl. 1900cc/100hp 1bbl",
    "condition": 3,
    "average_value": 19100
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1975,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 9200
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1975,
    "make": "Jaguar",
    "model": "XJ-12",
    "submodel": "C",
    "body_engine": "2dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 22900
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1975,
    "make": "Jaguar",
    "model": "XJ-12",
    "submodel": "L",
    "body_engine": "4dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 11300
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1975,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "C",
    "body_engine": "2dr Coupe 6-cyl. 4235/162hp 2x1 bbl",
    "condition": 3,
    "average_value": 17900
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1977,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr Sedan 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 9300
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "XJ-12",
    "submodel": "L",
    "body_engine": "4dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 11300
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1979,
    "make": "Jaguar",
    "model": "XJ-12L",
    "submodel": "Base",
    "body_engine": "4dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 11000
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1978,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr Sedan 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 8900
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "JX-S",
    "submodel": "GT",
    "body_engine": "2rd 2+2 Coupe 12-cyl 5343cc/244hp EFI",
    "condition": 3,
    "average_value": 12900
  },
  {
    "ymm": "1975-jaguar-xj-series-ii",
    "year": 1973,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "Base",
    "body_engine": "4dr Sedan 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 13800
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "XJ-12",
    "submodel": "L",
    "body_engine": "4dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 11300
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "XJ-S",
    "submodel": "GT",
    "body_engine": "2rd 2+2 Coupe 12-cyl 5343cc/244hp EFI",
    "condition": 3,
    "average_value": 12900
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr Sedan 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 9200
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "XJ-12",
    "submodel": "C",
    "body_engine": "2dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 22900
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1976,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "C",
    "body_engine": "2dr Coupe 6-cyl. 4235/162hp 2x1 bbl",
    "condition": 3,
    "average_value": 17900
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1975,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 9200
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1977,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 9300
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1979,
    "make": "Jaguar",
    "model": "XJ-12L",
    "submodel": "Base",
    "body_engine": "4dr Sedan 12-cyl. 5343cc/244 EFI",
    "condition": 3,
    "average_value": 11000
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1978,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "L",
    "body_engine": "4dr 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 8900
  },
  {
    "ymm": "1976-jaguar-xj-series-ii",
    "year": 1973,
    "make": "Jaguar",
    "model": "XJ-6",
    "submodel": "Base",
    "body_engine": "4dr 6-cyl. 4235cc/162hp 2x1bbl",
    "condition": 3,
    "average_value": 13800
  }
]