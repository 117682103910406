import { Container, Text, Heading, Stack, VStack } from '@chakra-ui/react';

const JJBestRVContent = () => {

  return (
    <Container maxW={'8xl'} p="5">
      <Stack align={['left']}>
        <Heading
          as="h1"
          size="xl"
          lineHeight="short"
          fontWeight="bold"
          color="secondary.600"
          maxW={{
            base: 'unset',
            lg: '800px',
          }}
        >
         Apply Now for a Great Rate to Own Your Dream RV
        </Heading>
      </Stack>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Text as="p" fontSize="lg">
        Whether it&apos;s a Class A motorhome, truck camper, Class B/C, popup or travel trailer. J.J. Best is here to serve you and get you on the road quicker in your RV. We offer great low rates, long terms and quick approvals.
        </Text>
        {/* <Text as="p" fontSize="lg">
        Ut dictum libero gravida hendrerit mollis. Quisque sed tellus in magna eleifend pellentesque a eget mauris. Integer dictum felis sed turpis vestibulum, sed rutrum libero varius. Sed tempus faucibus ultricies. Sed interdum, nisl quis lobortis maximus, ex ligula dapibus erat, sit amet egestas mauris sem vitae elit. Nam ac est dapibus, mollis enim eu, vehicula lorem. Sed diam odio, mattis at ipsum quis, vestibulum accumsan turpis.
        </Text> */}

        
      </VStack>
    </Container>
  );
};

export default JJBestRVContent;