import {
  Box,
  Flex,
  Heading,
  Image,
  Container
} from '@chakra-ui/react';
import aboutImage from '../../assets/photos/jjbest-woodie-landscape.jpg'

const FAQHero = () => {
  return (
    <>
    <Container
      bg="gray.800"
      as="section"
      minH="140px"
      position="relative"
      maxW="9xl"
    >
      <Box py="40" position="relative" zIndex={1}>
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
          color="white"
        >
          <Box maxW="3xl">
            <Heading as="h1" size="2xl" fontWeight="extrabold">
            Frequently Asked Questions
            </Heading>
            

            {/* <Heading size="md" fontWeight="normal" color="white" mt="10" mb="5">
            We understand the value of classic cars and believe more people should have the opportunity to experience the joy of owning them.
            </Heading> */}

            {/* <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              mt="10"
              spacing="4"
            >
              <Link href="/jjbest/application/app-start" passHref>
                <Button
                  as="a"
                  bgColor="other.400"
                  colorScheme="blue"
                  px="8"
                  mb="5"
                  rounded="full"
                  size="lg"
                  fontSize="md"
                  fontWeight="bold"
                  type="submit"
                >
                  Get pre-approved
                </Button>
              </Link>
              <HStack
                as="a"
                transition="background 0.2s"
                justify={{ base: 'center', md: 'flex-start' }}
                href="tel:8008721965"
                color="white"
                rounded="full"
                fontWeight="bold"
                px="6"
                py="3"
                _hover={{ bg: 'whiteAlpha.300' }}
              >
                <span>Call 1-800-USA-1965</span>
                <HiChevronRight />
              </HStack>
            </Stack> */}
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Image
            src={aboutImage}
            alt="JJ Best"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="top bottom"
            position="absolute"
          />
          <Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
        </Box>
      </Flex>
    </Container>
  </>
  );
};

export default FAQHero;
