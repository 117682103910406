import {
  Container,
  HStack,
  IconButton,
  Link,
  Divider,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaLinkedin, FaTwitterSquare, FaFacebookSquare } from 'react-icons/fa';
// import SocialButton from '../../widgets/SocialButton';
import FooterLogo from './FooterLogo';
import {
  // socialLinks,
  links,
} from '../../data/_jjbest_footerLinks';

const JJBestFooter = () => {
  const socialLinks = [
    { href: 'https://x.com', icon: <FaTwitterSquare /> },
    { href: 'https://facebook.com', icon: <FaFacebookSquare /> },
    { href: 'https://www.linkedin.com', icon: <FaLinkedin /> },
  ];
  return (
    <Container
      maxW={'9xl'}
      p="5"
      bg="primary.900"
      color="white"
      mx="auto"
      as="footer"
    >
      <Stack
        justify="space-between"
        align="start"
        direction={{ base: 'column', lg: 'row' }}
        py={{ base: '12', md: '16' }}
        gap="8"
      >
        <Stack gap={{ base: '4', md: '6' }} align="start">
          <FooterLogo height="32" />
          <Stack gap="1">
            <Text fontWeight="medium">Address</Text>
            <Text color="fg.muted">
              Banc Building 60 N Water Street, New Bedford, MA 02740
            </Text>
          </Stack>
          <Stack gap="1">
            <Text fontWeight="medium">Contact</Text>
            <Stack gap="0" color="fg.muted">
              <Link
                href="tel:+1-800-872-1965"
                // colorPalette="gray"
                color="fg.muted"
              >
                1-800-872-1965
              </Link>
              <Link
                href="mailto:support@logo.com"
                // colorPalette="gray"
                color="fg.muted"
              >
                cs@jjbest.com
              </Link>
            </Stack>
          </Stack>
        </Stack>
        <SimpleGrid
          columns={{ base: 2, md: 4 }}
          gap="8"
          width={{ base: 'full', lg: 'auto' }}
        >
          {links.map((group, idx) => (
            <Stack key={idx} gap="4" minW={{ lg: '40' }} textStyle="sm">
              <Text fontWeight="medium">{group.title}</Text>
              <Stack gap="3" alignItems="start">
                {group.links.map((link, idx) => (
                  <Link key={idx} href={link.href} color="fg.muted">
                    {link.label}
                  </Link>
                ))}
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
      <Divider />
      <Stack
        py="4"
        justify="space-between"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
      >
        {/* <Copyright /> */}
        <HStack gap="2">
          {socialLinks.map(({ href, icon }, index) => (
            <IconButton key={index} bgColor="white" aria-label={href}>
              <Link key={index} href={href}>
                {icon}
              </Link>
            </IconButton>
          ))}
        </HStack>
      </Stack>
    </Container>
  );
};

export default JJBestFooter;
