import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Link,
} from '@chakra-ui/react'
import rvnewbie from "../../assets/photos/rv-newbie.jpg"
import rveducation from "../../assets/photos/rv-education.jpg"
import rvtips from "../../assets/photos/rv-tips.jpg";



const RVResources = () => {

  const features = [
    {
      title: 'RV Newbie? Top 10 things every new RV owner should know.',
      img: rvnewbie,
      url: 'https://www.youtube.com/embed/BsEs-CLBbaU?feature=oembed&autoplay=1'
    },
    {
      title: 'Check out RV Education 101 regarding essential supplies.',
      img: rveducation,
      url: 'https://www.youtube.com/embed/ldNRrdw2qis&t=4s?feature=oembed&autoplay=1'
    },
    {
      title: 'Here are some simple tips from RV veterans.',
      img: rvtips,
      url: 'https://www.youtube.com/embed/dPZXba-Q8B8?feature=oembed&autoplay=1'
    },
  ]

  return (
    <Container maxW="8xl" p="5" mb="10">
    <Stack align={{ md: 'center' }} gap={{ base: '8', md: '20' }}>
      <Stack align={{ base: 'flex-start', md: 'center' }} gap="2" maxW="3xl" w="full">
        <Heading as="h2" textStyle={{ base: '3xl', md: '4xl' }} textAlign={{ md: 'center' }}>
        New to RVs? Start your journey today.
        </Heading>
      </Stack>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        columnGap="8"
        rowGap={{ base: '10', lg: '16' }}
      >
        {features.map((feature) => (
          <Stack key={feature.title} gap="4">
            <Stack gap="5">
              <Link href={feature.url} isExternal>
              <Image src={feature.img} alt={feature.description} h="72" objectFit="cover" />
              </Link>
              
              <Stack gap="1">
                <Text fontWeight="medium" textStyle={{ base: 'lg', md: 'xl' }}>
                  {feature.title}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </SimpleGrid>
    </Stack>
  </Container>
  );
};

export default RVResources;
