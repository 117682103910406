import React, { useEffect } from 'react';
import AboutJJBestHero from '../../../components/jjbest/AboutJJBestHero';
import AboutJJBestContent from '../../../components/jjbest/AboutJJBestContent';
import JJBestFooter from '../../../components/jjbest/JJBestFooter';
import JJBestDisclosures from '../../../components/jjbest/JJBestDisclosures';
import LandingLayoutJJBest from '../../../components/jjbest/LandingLayoutJJBest';
import LoanCalculator from '../../../components/jjbest/LoanCalculator';
import protectedRoute from '../../../utils/protectedRoute';

const AboutJJBest = props => {
  const { userInfo } = props;

  useEffect(() => {}, [userInfo]);

  return (
    <LandingLayoutJJBest>
      <AboutJJBestHero />
      <AboutJJBestContent />
      <LoanCalculator />
      <JJBestFooter />
      <JJBestDisclosures />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(AboutJJBest);
