import { Heading, Stack, Text } from '@chakra-ui/react'

const FaqSectionHeader = (props) => {
  const { tagline, headline, description, ...rootProps } = props
  return (
    <Stack gap={{ base: '6', md: '8' }} {...rootProps}>
      <Stack gap={{ base: '4', md: '5' }}>
        <Stack gap={{ base: '2', md: '3' }}>
          {tagline && (
            <Text textStyle={{ base: 'sm', md: 'md' }} fontWeight="medium" color="colorPalette.fg">
              {tagline}
            </Text>
          )}
          <Heading as="h2" 
          // textStyle={{ base: '5xl', md: '5xl' }}
          >
            {headline}
          </Heading>
        </Stack>
        {description && (
          <Text color="fg.muted" textStyle={{ base: 'md', md: 'lg' }} maxW="3xl">
            {description}
          </Text>
        )}
      </Stack>
      {props.children}
    </Stack>
  )
}

export default FaqSectionHeader