import {
  Stack,
  CircularProgress,
  Box,
  Flex,
  Heading,
  FormControl,
  Wrap,
  WrapItem,
  Spacer,
  Divider,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import LoanCalculator from '../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';
import ArticleHero from '../../../../components/heros/ArticleHero';
import { articleByUrl } from '../../../../graphql/queries';
import GetArticleSectionItems from '../../../../components/articles/GetArticleSectionItems';

const _ = require('lodash');

const ResourcesArticleSlug = props => {
  const { userInfo } = props;
  const { slug } = useParams();
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [articleTags, setArticleTags] = useState([]);
  const [thisArticleSectionData, setThisArticleSectionData] = useState([]);
  const color1 = mode('gray.100', 'gray.800');

  useEffect(() => {
    const handleFetchArticle = async () => {
      if (slug) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: articleByUrl,
            variables: { url: slug },
          });
          console.log("article: ", result.data.articleByUrl.items[0])
          setArticle(result.data.articleByUrl.items[0]);
          handleSortedArticleTags(
            result.data.articleByUrl.items[0].articleTags.items
          );
          const sectionResult = await result.data.articleByUrl.items[0]
            .articleSections.items;
          setThisArticleSectionData(sectionResult);
          setLoading(false);
        } catch (err) {
          console.log('error in fetchArticle: ', err);
        }
      }
    };
    const handleSortedArticleTags = async data => {
      if (slug) {
        const sortedArticleTagData = _.sortBy(data, ['articleTags'], ['asc']);
        setArticleTags(sortedArticleTagData);
        // setLoading(false);
      }
    };
    handleFetchArticle();
  }, [userInfo, slug]);

  return loading ? (
    <CircularProgress />
  ) : (
    <LandingLayoutJJBest>
      <Stack spacing={4}>
        {article === null || undefined ? (
          <div></div>
        ) : (
          <ArticleHero article={article} />
        )}

        <Box
          //bg={mode('gray.100', 'gray.800')}
          as="section"
          py={{
            base: '12',
            md: '24',
          }}
        >
          <Box
            maxW={{
              base: 'xl',
              md: '7xl',
            }}
            mx="auto"
            px={{
              base: '6',
              md: '8',
            }}
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              spacing={{
                base: '12',
                xl: '20',
              }}
              //align="center"
            >
              <Box flex="1">
                <GetArticleSectionItems
                  articleSectionData={thisArticleSectionData}
                />
              </Box>
              <Box
                maxW="450px"
                flex="1"
                h={{
                  base: '240px',
                  md: '400px',
                }}
                flexShrink={0}
                align="center"
                //bg={mode('gray.50', 'gray.800')}
              >
                <Flex
                  height="50vh"
                  width={{
                    base: 'full',
                    sm: 'xs',
                  }}
                  direction="column"
                  borderRightWidth="1px"
                  px={6}
                  py={8}
                  bg={color1}
                  align="center"
                >
                  <Stack spacing={6}>
                    {articleTags === null || undefined ? (
                      <div></div>
                    ) : (
                      <RelatedArticleTags articleTags={articleTags} />
                    )}

                    {/* <Divider /> */}

                    {/* <SocialButtons /> */}
                  </Stack>
                  <Spacer />
                </Flex>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Divider />
      <LoanCalculator />
      <JJBestFooter />
    </LandingLayoutJJBest>
  );
};

const RelatedArticleTags = props => {
  const { articleTags } = props;
  const navigate = useNavigate();

  useEffect(() => {}, [articleTags]);

  function handleSearchTagClick(e, tag) {
    e.preventDefault();
    navigate(`/jjbest/resources/searchbytag/${tag.articleTagName}`);
  }

  return articleTags === null || undefined ? (
    <CircularProgress />
  ) : (
    <Flex h="full" direction="column" px="4" py="4">
      <Stack spacing={4}>
        <Heading as="h4" size="md">
          More on this topic
        </Heading>
        <FormControl id="taglist">
          <Wrap width="full" spacing="2">
            {articleTags.map(tag => {
              return (
                <WrapItem key={tag.id}>
                  <Box
                    as="button"
                    key={tag.id}
                    borderRadius="sm"
                    bg="secondary.700"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    px={4}
                    py={1}
                    h={8}
                    onClick={e => handleSearchTagClick(e, tag)}
                  >
                    {tag.articleTagName}
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </FormControl>
      </Stack>
    </Flex>
  );
};

export default ResourcesArticleSlug;
