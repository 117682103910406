import React, { useEffect } from 'react';
import JJBestAirplaneHero from '../../../../components/jjbest/JJBestAirplaneHero';
import JJBestAirplaneContent from '../../../../components/jjbest/JJBestAirplaneContent';
import LoanCalculator from '../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';
import JJBestDisclosures from "../../../../components/jjbest/JJBestDisclosures";
import JJBestFeatures from '../../../../components/jjbest/JJBestFeatures';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import JJBestTestimonials from "../../../../components/jjbest/JJBestTestamonials";
import LatestArticles from "../../../../components/jjbest/LatestArticles";
import protectedRoute from '../../../../utils/protectedRoute';

const JJBestAirplaneFinancingNoNav = props => {
  const { userInfo } = props;

  useEffect(() => {}, [userInfo]);

  return (
    <LandingLayoutJJBest>
      <JJBestAirplaneHero />
      <JJBestAirplaneContent />
      <JJBestFeatures />
      <LoanCalculator />
      <JJBestTestimonials />
      <LatestArticles />
      <JJBestFooter />
      <JJBestDisclosures />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(JJBestAirplaneFinancingNoNav);
