import React, { useEffect } from 'react';
import JJBestRVHero from '../../../../components/jjbest/JJBestRVHero';
import JJBestRVContent from '../../../../components/jjbest/JJBestRVContent';
import LoanCalculator from '../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';
import JJBestDisclosures from "../../../../components/jjbest/JJBestDisclosures";
import JJBestFeatures from '../../../../components/jjbest/JJBestFeatures';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import JJBestTestimonials from "../../../../components/jjbest/JJBestTestamonials";
import RVResources from "../../../../components/jjbest/RVResources";
import protectedRoute from '../../../../utils/protectedRoute';

const JJBestRVFinancingNoNav = props => {
  const { userInfo } = props;

  useEffect(() => {}, [userInfo]);

  return (
    <LandingLayoutJJBest>
      <JJBestRVHero />
      <JJBestRVContent />
      <JJBestFeatures />
      <LoanCalculator />
      <JJBestTestimonials />
      <RVResources />
      <JJBestFooter />
      <JJBestDisclosures />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(JJBestRVFinancingNoNav);
