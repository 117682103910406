import { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { formatter } from '../../utils/currencyFormatter';
import SectionHeader from '../../widgets/SectionHeader';
import { valuation_data } from '../../data/_sampleValuationData';

const _ = require('lodash');

const ValuationContent = () => {
  const ymm = useParams();

  const [valuationData, setValuationData] = useState([]);
  const [ymmString, setYmmString] = useState("");

  useEffect(() => {
    const handleFetchData = async () => {
      if (ymm) {
        const result = _.filter(valuation_data, function (item) {
          return item.ymm === ymm.ymm;
        });
        setValuationData(result);
      }
    };
    const handleYmmString = async () => {
      if(ymm) {
        const ymmArray = ymm.ymm.split("-")
        setYmmString(ymmArray.join(" "));
      }
    }

    handleFetchData();
    handleYmmString()
  }, [ymm]);

  return (
    <Container maxW={'8xl'} p="5" m="10">
      <Box p="5"  bgColor="gray.50">
      <Flex
         gap={{ base: '5', md: '10' }}
        direction={{ base: 'column', md: 'row' }}
       
      >
        <SectionHeader
          flex="1"
          maxW="xl"
          headline={`Average ${ymmString} values`}
          description="Average Prices According to Hagerty Valuation Tools for a #3 condition vehicle"
          subdescription="#3 vehicles drive and run well but are not used for daily transportation. The casual passerby will not find any visual flaws, but these vehicles might have some incorrect parts."
        />
        <SimpleGrid columns={3} gap="8" flex="1">
          {valuationData &&
            valuationData.slice(0, 9).map(item => (
              <Stack gap="0" pb="4" borderBottomWidth="1px" key={item.label}>
                <Text textStyle="4xl" fontWeight="semibold">
                  {formatter.format(
                    parseInt(item.average_value, 10).toFixed(0)
                  )}
                </Text>
                <Text>
                  {item.year} {item.make} {item.model} {item.submodel}{' '}
                  {item.body_engine}
                </Text>
              </Stack>
            ))}
        </SimpleGrid>
      </Flex>
      </Box>
      
    </Container>
  );
};

export default ValuationContent;
